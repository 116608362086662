import { KohostHTTPClient as Client } from "@kohost/api-client/client";
import { constructEntity } from "lib/utils";
import { getApiUrl } from "utils/getApiUrl";
import getOrganizationId from "utils/getOrganizationId";

let organization = getOrganizationId();

if (organization === "auth") organization = null;

const apiUrl = getApiUrl();

const kohost = new Client({
  url: `${apiUrl}v3/`,
  organizationId: organization,
  onSuccess: (response) => {
    if (Array.isArray(response.data)) {
      response.data = response.data.map((responseData) => {
        try {
          if (!responseData) return responseData;
          return constructEntity(responseData);
        } catch (error) {
          console.error(error);

          return responseData;
        }
      });
    } else if (response.data?.type) {
      try {
        response.data = constructEntity(response.data);
      } catch (error) {
        console.error(error);
        if (error.cause) {
          console.error(error.cause);
        }
      }
    }

    return response;
  },
});

kohost.on("LoginRequired", () => {
  if (window.location.pathname !== "/login") {
    // if query string of pwaToken is present, redirect to /login?token={pwaToken}
    if (window.location.search) {
      const params = new URLSearchParams(window.location.search);
      const token = params.get("pwaToken");
      if (token) {
        window.location.href = "/login?token=" + token;
      }
    } else {
      window.location.href = "/login";
    }
  }
});

kohost.on("PhoneVerificationRequired", () => {
  if (window.location.pathname !== "/verify-phone") {
    window.location.href = "/verify-phone";
  }
});

export default kohost;
