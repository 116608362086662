import {
  Alarm,
  Announcement,
  Camera,
  Category,
  Courtesy,
  Credential,
  Dimmer,
  DiscoveredDevice,
  Identification,
  Issue,
  Lock,
  MediaFile,
  MediaSource,
  MotionSensor,
  Order,
  Organization,
  Policy,
  Product,
  Property,
  Reservation,
  Room,
  Scene,
  Space,
  Switch,
  Thermostat,
  Ticket,
  TimeSheet,
  User,
  Vendor,
  WindowCovering,
} from "@kohost/api-client/models";
import { clsx } from "clsx";
import { twMerge } from "tailwind-merge";

export function cn(...inputs) {
  return twMerge(clsx(inputs));
}

export function upperFirst(string) {
  if (typeof string !== "string") throw new Error("input must be a string");
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function entityFactory(type) {
  switch (type) {
    case "reservation":
      return Reservation;
    case "mediaFile":
      return MediaFile;
    case "switch":
      return Switch;
    case "alarm":
      return Alarm;
    case "dimmer":
      return Dimmer;
    case "lock":
      return Lock;
    case "thermostat":
      return Thermostat;
    case "windowCovering":
      return WindowCovering;
    case "identification":
      return Identification;
    case "user":
      return User;
    case "policy":
      return Policy;
    case "courtesy":
      return Courtesy;
    case "camera":
      return Camera;
    case "mediaSource":
      return MediaSource;
    case "room":
      return Room;
    case "space":
      return Space;
    case "category":
      return Category;
    case "ticket":
      return Ticket;
    case "scene":
      return Scene;
    case "product":
      return Product;
    case "order":
      return Order;
    case "discoveredDevice":
      return DiscoveredDevice;
    case "credential":
      return Credential;
    case "announcement":
      return Announcement;
    case "timeSheet":
      return TimeSheet;
    case "property":
      return Property;
    case "organization":
      return Organization;
    case "issue":
      return Issue;
    case "vendor":
      return Vendor;
    default:
      return null;
  }
}

export function constructEntity(data) {
  if (!data) return data;
  if (!data.type) return data;
  if (data.type === "room") return constructRoom(data);
  if (data.type === "user") return constructUser(data);
  const Entity = entityFactory(data.type);
  if (Entity === null) return data;
  return new Entity(data);
}

export function constructRoom(room) {
  const roomData = {
    ...room,
    type: "room",
  };

  roomData.switches = room.switches.map((s) => new Switch(s));
  roomData.dimmers = room.dimmers.map((d) => new Dimmer(d));
  roomData.locks = room.locks.map((l) => new Lock(l));
  roomData.thermostats = room.thermostats.map((t) => new Thermostat(t));
  roomData.windowCoverings = room.windowCoverings.map(
    (w) => new WindowCovering(w),
  );
  roomData.cameras = room.cameras.map((c) => new Camera(c));
  roomData.motionSensors = room.motionSensors.map((m) => new MotionSensor(m));
  roomData.mediaSources = room.mediaSources.map((m) => new MediaSource(m));
  roomData.locks = room.locks.map((l) => new Lock(l));
  roomData.courtesy = room.courtesy.map((c) => new Courtesy(c));
  roomData.alarms = room.alarms.map((a) => new Alarm(a));

  return new Room(roomData);
}

export function constructUser(user) {
  if (user.photo) user.photo = new MediaFile(user.photo);
  if (user.reservations)
    user.reservations = user.reservations.map((r) => new Reservation(r));

  if (user.identifications)
    user.identifications = user.identifications.map(
      (i) => new Identification(i),
    );

  if (user.permissions) {
    user.permissions = user.permissions.map((permission) => {
      if (permission.policies && Array.isArray(permission.policies)) {
        permission.policies = permission.policies.map(
          (policy) => new Policy(policy),
        );
      }
      return permission;
    });
  }

  return new User(user);
}

export function getFirstItem(array) {
  if (!Array.isArray(array)) return array;
  return array[0];
}

export function areEqual(a, b) {
  // compare objects
  if (typeof a === "object" && typeof b === "object") {
    return JSON.stringify(a) === JSON.stringify(b);
  }

  // compare Arrays
  if (Array.isArray(a) && Array.isArray(b)) {
    return JSON.stringify(a) === JSON.stringify(b);
  }

  return a === b;
}
