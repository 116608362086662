const configApiUrl = import.meta.env.VITE_API_URL;
export function getApiUrl() {
  let apiUrl = configApiUrl ? configApiUrl : "/api/";

  // and the origin if it's not already there
  if (apiUrl.indexOf(window.location.origin) === -1) {
    apiUrl = window.location.origin + apiUrl;
  }

  if (apiUrl[apiUrl.length - 1] !== "/") {
    apiUrl += "/";
  }

  return apiUrl;
}
