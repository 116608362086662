import * as Sentry from "@sentry/react";
import React from "react";
import { useRouteError } from "react-router-dom";
import "./services/sentry";

const failedImportMessages = [
  "failed to fetch dynamic import",
  "loading chunk",
  "failed to fetch dynamically imported module",
];

export const ErrorMessage = () => {
  const error = useRouteError();
  console.error(error);
  const errMessage = error?.message?.toLowerCase() || "";
  const isFailedImport = failedImportMessages.some((msg) =>
    errMessage.includes(msg),
  );
  if (isFailedImport) return <UpdateNeededError />;
  return (
    <div className="grid h-screen animate-fadeIn place-items-center">
      <div className="container mx-auto flex max-w-lg flex-col items-center rounded-md bg-[#474e5f] p-8">
        <h1 className="mb-2 text-2xl font-medium">
          Oops! Something went wrong here.
        </h1>
        <p className="mb-4 text-lg text-[#6e7994]">
          Our team has been notified so that we can get this fixed. If the error
          persists, please try again later.
        </p>
        <button
          onClick={() => {
            window.location.href = "/";
          }}
          className="block rounded-md bg-[#369FFF] px-8 py-2 text-white"
        >
          Back Home
        </button>
      </div>
    </div>
  );
};

export const UpdateNeededError = () => {
  return (
    <div className="grid h-screen animate-fadeIn place-items-center">
      <div className="container mx-auto flex max-w-lg flex-col items-center rounded-md bg-[#474e5f] p-8">
        <h1 className="text-2xl font-medium">Update Needed</h1>
        <h3 className="mb-4 text-center text-lg text-[#6e7994]">
          Your app needs an update in order to continue. Please tap the button
          below.
        </h3>
        <button
          onClick={() => {
            window.location.href = "/";
          }}
          className="block rounded-md bg-[#369FFF] px-8 py-2 text-white"
        >
          Update Now
        </button>
      </div>
    </div>
  );
};

const ErrorBoundary = ({ children, fallback: Fallback }) => {
  return (
    <Sentry.ErrorBoundary fallback={Fallback ? <Fallback /> : ErrorMessage}>
      {children}
    </Sentry.ErrorBoundary>
  );
};

export default ErrorBoundary;
