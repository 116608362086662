import React, { createRef, useEffect } from "react";
import {
  RouterProvider,
  ScrollRestoration,
  createBrowserRouter,
  matchPath,
  useLocation,
  useOutlet,
} from "react-router-dom";
import ErrorBoundary, { ErrorMessage } from "../ErrorBoundary";

import { CSSTransition, TransitionGroup } from "react-transition-group";

import { useDocumentTitle } from "@uidotdev/usehooks";
import NotFound from "NotFound";
import { useProperty } from "hooks/use-property";
import { useScreenBlur } from "hooks/use-screenBlur";

const defaultAnimations = {
  enterActive:
    "motion-safe:animate-enterFromBottom entering fixed z-10 bg-dark-gray w-full",
  enterDone: "entered",
  exitActive:
    "motion-safe:animate-exitToBottom exiting fixed z-10 bg-dark-gray w-full",
};

const defaultChildAnimations = {
  enterActive:
    "motion-safe:animate-fadeIn entering fixed z-20 bg-dark-gray w-full",
  enterDone: "entered",
  exitActive:
    "motion-safe:animate-fadeOut exiting fixed z-10 bg-dark-gray w-full",
};

const routes = [
  {
    index: true,
    path: "/",
    name: "Home",
    async lazy() {
      const { Home } = await import("./Home/Home");
      return {
        Component: Home,
      };
    },
    nodeRef: createRef(),
    animate: {
      enterActive: "fixed group/home -z-10 left-0 right-0",
      enterDone: "",
      exitActive: "",
    },
  },
  {
    path: "/verify-phone",
    name: "Verify Phone",
    async lazy() {
      const { VerifyPhone } = await import("./VerifyPhone");
      return {
        Component: VerifyPhone,
      };
    },
    nodeRef: createRef(),
    animate: defaultAnimations,
  },
  {
    path: "/sos",
    name: "SOS",
    async lazy() {
      const { SOS } = await import("./SOS");
      return {
        Component: SOS,
      };
    },
    nodeRef: createRef(),
    animate: defaultAnimations,
  },
  // {
  //   path: "/reports",
  //   name: "Reports",
  //   children: [
  //     {
  //       name: "Ticket Reports",
  //       path: "tickets",
  //       async lazy() {
  //         const { TicketReports } = await import(
  //           "./Reports/Tickets/TicketReports"
  //         );
  //         return {
  //           Component: TicketReports,
  //         };
  //       },
  //     },
  //   ],
  //   nodeRef: createRef(),
  //   animate: defaultChildAnimations,
  // },
  {
    path: "/room-control",
    name: "Room Control",
    children: [
      {
        index: true,
        name: "Room Control",
        async lazy() {
          const { RoomControl } = await import("./RoomControl");
          return {
            Component: RoomControl,
          };
        },
      },
      {
        path: "climate",
        name: "Climate",
        async lazy() {
          const { ClimatePage } = await import("./RoomControl/pages/Climate");
          return {
            Component: ClimatePage,
          };
        },
      },
      {
        path: "lights",
        name: "Lights",
        async lazy() {
          const { LightsPage } = await import("./RoomControl/pages/Lights");
          return {
            Component: LightsPage,
          };
        },
      },
      {
        path: "shades",
        name: "Shades",
        async lazy() {
          const { ShadesPage } = await import("./RoomControl/pages/Shades");
          return {
            Component: ShadesPage,
          };
        },
      },
      {
        path: "tv",
        name: "TV",
        async lazy() {
          const { TVPage } = await import("./RoomControl/pages/TV");
          return {
            Component: TVPage,
          };
        },
      },
      {
        path: "cameras",
        name: "Cameras",
        async lazy() {
          const { CamerasPage } = await import("./RoomControl/pages/Cameras");
          return {
            Component: CamerasPage,
          };
        },
      },
      {
        path: "security",
        name: "Security",
        async lazy() {
          const { SecurityAlarmPage } = await import(
            "./RoomControl/pages/Security"
          );
          return {
            Component: SecurityAlarmPage,
          };
        },
      },
      {
        path: "pa",
        name: "PA System",
        async lazy() {
          const { PASystemPage } = await import("./RoomControl/pages/PASystem");
          return {
            Component: PASystemPage,
          };
        },
      },
      {
        path: "doors",
        name: "Doors",
        async lazy() {
          const { LocksPage } = await import("./RoomControl/pages/Locks");
          return {
            Component: LocksPage,
          };
        },
      },
      {
        path: "scenes",
        name: "Scenes",
        async lazy() {
          const { ScenesPage } = await import("./RoomControl/pages/Scenes");
          return {
            Component: ScenesPage,
          };
        },
      },
    ],
    nodeRef: createRef(),
    animate: defaultChildAnimations,
  },
  {
    path: "/check-in",
    name: "Check In",
    async lazy() {
      const { CheckIn } = await import("./CheckIn");
      return {
        Component: CheckIn,
      };
    },
    nodeRef: createRef(),
    animate: defaultAnimations,
  },
  {
    path: "/check-out",
    name: "Check Out",
    async lazy() {
      const { CheckOut } = await import("./CheckOut");
      return {
        Component: CheckOut,
      };
    },
    nodeRef: createRef(),
    animate: defaultAnimations,
  },
  {
    path: "/users/*",
    name: "Users",
    async lazy() {
      const { Users } = await import("./Users");
      return {
        Component: Users,
      };
    },
    nodeRef: createRef(),
    animate: defaultChildAnimations,
  },
  {
    path: "/timesheets/*",
    name: "Time Tracking",
    async lazy() {
      const { TimeTracking } = await import("./Concierge/TimeTracking");
      return {
        Component: TimeTracking,
      };
    },
    nodeRef: createRef(),
    animate: defaultAnimations,
  },
  {
    path: "/marketing",
    name: "Marketing",
    async lazy() {
      const { Marketing } = await import("./Marketing");
      return {
        Component: Marketing,
      };
    },
    nodeRef: createRef(),
    animate: defaultChildAnimations,
  },
  {
    path: "/concierge",
    name: "Concierge",
    nodeRef: createRef(),
    animate: defaultChildAnimations,
    children: [
      {
        index: true,
        name: "Concierge",
        async lazy() {
          const { Concierge } = await import("./Concierge");
          return {
            Component: Concierge,
          };
        },
      },
      {
        name: "Concierge Settings",
        path: "settings",
        async lazy() {
          const { ConciergeSettings } = await import(
            "./Concierge/ConiergeSettings"
          );
          return {
            Component: ConciergeSettings,
          };
        },
      },
    ],
  },
  {
    path: "/device-management",
    name: "Device Management",
    async lazy() {
      const { DeviceManagement } = await import("./DeviceManagement");
      return {
        Component: DeviceManagement,
      };
    },
    nodeRef: createRef(),
    animate: defaultAnimations,
  },
  {
    path: "/space-management",
    name: "Space Management",
    async lazy() {
      const { SpaceManagement } = await import("./SpaceManagement");
      return {
        Component: SpaceManagement,
      };
    },
    nodeRef: createRef(),
    animate: defaultAnimations,
  },
];

const AnimatedRouter = () => {
  const location = useLocation();
  const currentOutlet = useOutlet();

  const { unblurScreen } = useScreenBlur();
  const { organization } = useProperty();

  useEffect(() => {
    unblurScreen();
    //window.scrollTo(0, 0, { behavior: "instant" });
    // ga.sendPageView(location.pathname);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  let { nodeRef, animate, children, path, name } =
    routes.find((route) => {
      // check if pathname is exactly route.path or a child of route.path
      const match = matchPath(route.path, location.pathname);
      if (match) return true;
      if (location.pathname.startsWith(route.path) && route.path !== "/")
        return true;
      return false;
    }) ?? {};

  if (!nodeRef) nodeRef = createRef();

  const isChildRoute = children?.some((child) => {
    if (!child.path) return false;
    const fullPath = `${path}/${child.path}`;
    const match = matchPath(fullPath, location.pathname);
    if (match) return true;
    return false;
  });

  if (isChildRoute) nodeRef = createRef();

  useDocumentTitle(
    `${name} | ${organization?.name || "Kohost"} powered by Kohost`,
  );

  return (
    <>
      <TransitionGroup component={null}>
        {animate ? (
          <CSSTransition
            in={true}
            key={location.pathname}
            nodeRef={nodeRef}
            timeout={animate ? 300 : 0}
            classNames={animate}
            unmountOnExit
            onEnter={() => {
              window.scrollTo(0, 0);
            }}
          >
            {() => (
              <div ref={nodeRef} className="page">
                {currentOutlet}
              </div>
            )}
          </CSSTransition>
        ) : (
          <div ref={nodeRef} className="page">
            {currentOutlet}
          </div>
        )}
      </TransitionGroup>
      <ScrollRestoration />
    </>
  );
};

const router = createBrowserRouter([
  {
    path: "/",
    element: <AnimatedRouter />,
    errorElement: (
      <ErrorBoundary>
        <ErrorMessage />
      </ErrorBoundary>
    ),
    children: routes.map((route) => ({
      index: route.index,
      path: route.path === "/" ? undefined : route.path,
      element: route.element,
      children: route.children,
      lazy: route.lazy,
      state: route.state,
    })),
  },
  {
    path: "/login",
    async lazy() {
      const { Login } = await import("./Login/Login");
      return {
        Component: Login,
      };
    },
  },
  {
    path: "*",
    element: <NotFound />,
  },
]);

const Router = () => {
  return <RouterProvider router={router} />;
};

export default Router;
