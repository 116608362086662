import { getFirstItem } from "lib/utils";
import {
  createFetchOptions,
  fetchHandler,
  useFetch,
  useFetchAll,
  useMutate,
  useQueryClient,
} from "./use-fetch";
import { useLocalStorage } from "./use-local-storage";
import { useProperty } from "./use-property";

import {
  CreateRoomInSpaceCommand,
  CreateSpaceCommand,
  DescribeSpaceCommand,
  ListRoomsInSpaceCommand,
  ListSpacesCommand,
  UpdateSpaceCommand,
} from "@kohost/api-client/useCases";

const SPACE_QUERY_KEY = "spaces";

export const useSpaces = (
  query = {},
  opts = {
    enable: true,
    propertyId: null,
    fetchRooms: true,
  },
) => {
  const { property, currentPropertyId } = useProperty();

  const propertyId = opts.propertyId || currentPropertyId;

  const spaceFetchOptions = createFetchOptions(propertyId, SPACE_QUERY_KEY);

  const { key: SPACES, headers: spaceReqHeaders } = spaceFetchOptions;

  const globalCommonAreas =
    property?.appFeatures?.RoomControl?.commonAreas?.spaces || [];

  const [currentSpaceId, setCurrentSpaceId] = useLocalStorage(
    `property-${propertyId}-currentSpaceId`,
  );

  const spacesQuery = useFetch({
    queryFn: fetchHandler({
      useCase: new ListSpacesCommand({
        query,
        headers: spaceReqHeaders,
      }),
    }),
    queryKey: SPACES,
    enabled: opts.enable,
    retry: 3,
    placeholderData: [],
    meta: {
      cacheById: true,
    },
  });

  const spaces = spacesQuery.data || [];
  const nonGlobalCommonAreas = spaces?.find(
    (s) => !globalCommonAreas.includes(s.id),
  );
  const defaultSpace = nonGlobalCommonAreas || spaces[0];
  const defaultSpaceId = defaultSpace?.id;
  const currentSpaceIsInvalid = Boolean(
    !spaces.find((s) => s.id === currentSpaceId),
  );

  if (!currentSpaceId && defaultSpaceId) {
    setCurrentSpaceId(defaultSpaceId);
  } else if (currentSpaceId && currentSpaceIsInvalid) {
    if (defaultSpaceId) setCurrentSpaceId(defaultSpaceId);
    else setCurrentSpaceId(null);
  }

  const roomQueries = spacesQuery.data
    ?.map((space) => {
      return {
        queryKey: [...SPACES, space.id, "rooms"],
        queryFn: fetchHandler({
          useCase: new ListRoomsInSpaceCommand({
            data: { id: space.id },
            headers: spaceReqHeaders,
          }),
        }),
        staleTime: 1000 * 60 * 1, // 1 minute
        enabled: Boolean(opts.enable && opts.fetchRooms),
        refetchOnWindowFocus: true,
      };
    })
    .filter((query) => query.enabled);

  const spaceRoomsQuery = useFetchAll({
    queries: roomQueries || [],
  });

  const spaceId = opts.enable ? currentSpaceId : null;
  const currentSpace = useSpace(spaceId);
  const hotelRooms = spacesQuery.data?.filter(
    (s) => s.discriminator === "hotelRoom",
  );
  const globalCommonArea = spacesQuery.data?.filter((s) =>
    globalCommonAreas.includes(s.id),
  );

  return {
    ...spacesQuery,
    hotelRooms: hotelRooms,
    commonAreas: globalCommonArea,
    currentSpaceId,
    changeSpace: setCurrentSpaceId,
    currentSpace,
    spaceRooms: spaceRoomsQuery,
  };
};

export const useSpace = (id = null) => {
  const { currentPropertyId } = useProperty();
  const fetchOptions = createFetchOptions(currentPropertyId, SPACE_QUERY_KEY);
  const { key: SPACES, headers: spaceReqHeaders } = fetchOptions;
  const queryClient = useQueryClient();

  const spaceQuery = useFetch({
    queryFn: fetchHandler({
      useCase: new DescribeSpaceCommand({
        data: { id },
        headers: spaceReqHeaders,
      }),
      options: { transformResponse: getFirstItem },
    }),
    queryKey: [...SPACES, id],
    enabled: Boolean(id),
  });

  const spaceRoomsQuery = useFetch({
    queryFn: fetchHandler({
      useCase: new ListRoomsInSpaceCommand({
        data: { id },
        headers: spaceReqHeaders,
      }),
    }),
    queryKey: [...SPACES, id, "rooms"],
    staleTime: 1000 * 60 * 1, // 1 minute
    enabled: Boolean(id),
  });

  const createSpaceMutation = useMutate({
    mutationFn: (space) =>
      fetchHandler({
        useCase: new CreateSpaceCommand({
          data: space,
          headers: spaceReqHeaders,
        }),
        options: { transformResponse: getFirstItem },
      })(),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: SPACES });
    },
  });

  const createRoomInSpaceMutation = useMutate({
    mutationFn: (vars) =>
      fetchHandler({
        useCase: new CreateRoomInSpaceCommand({
          data: vars,
          headers: spaceReqHeaders,
        }),
        options: { transformResponse: getFirstItem },
      })(),
  });

  const editSpaceMutation = useMutate({
    mutationFn: (space) =>
      fetchHandler({
        useCase: new UpdateSpaceCommand({
          data: space,
          headers: spaceReqHeaders,
        }),
        options: { transformResponse: getFirstItem },
      })(),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: SPACES });
    },
  });

  return {
    ...spaceQuery,
    rooms: spaceRoomsQuery,
    createSpace: createSpaceMutation,
    createRoomInSpace: createRoomInSpaceMutation,
    editSpace: editSpaceMutation,
  };
};
