import { KohostSocketIoClient as SocketIoClient } from "@kohost/api-client/socketIoClient";
import { RefreshTokenCommand } from "@kohost/api-client/useCases";
import React, { createContext, useContext, useEffect } from "react";
import kohost from "services/kohost";
import { captureException } from "services/sentry";
import { getApiUrl } from "utils/getApiUrl";
import { demoOrgs } from "utils/getOrganizationId";
import { useAuth } from "./use-auth";
import { useProperty } from "./use-property";

const apiUrl = getApiUrl();

const socketContext = createContext();

export function ProvideSocketIo({ children }) {
  const { connected, client } = useProvideSocketIo();

  return (
    <socketContext.Provider
      value={{
        client: client,
        connected,
      }}
    >
      {children}
    </socketContext.Provider>
  );
}

export const useSocketIo = () => {
  return useContext(socketContext);
};

export const useProvideSocketIo = () => {
  const { data: currentUser } = useAuth();

  const currentUserId = currentUser?.id;

  const {
    property,
    loading: propertyLoading,
    currentPropertyId,
    organizationId,
  } = useProperty();

  const isDemoOrg = demoOrgs.includes(organizationId);

  const socketClient = React.useMemo(() => {
    let url = apiUrl;
    if (apiUrl.includes("/api/")) {
      url = apiUrl.replace("/api/", `/${currentPropertyId}`);
    } else {
      url = apiUrl + `${currentPropertyId}`;
    }

    return new SocketIoClient({ url });
  }, [currentPropertyId]);

  // if property ID changes, we need to destroy the old socket and create a new one
  useEffect(() => {
    if (!currentPropertyId || isDemoOrg) return;
    socketClient.disconnect();
    socketClient.connect();
    return () => {
      socketClient.disconnect();
    };
  }, [currentPropertyId, socketClient, isDemoOrg]);

  const [connected, setConnected] = React.useState(false);
  const timeout = React.useRef(null);
  const failCount = React.useRef(0);

  useEffect(() => {
    if (
      currentUserId &&
      !connected &&
      property &&
      !propertyLoading &&
      !isDemoOrg
    ) {
      console.log("connecting socket");
      // only connect if we are logged in and not already connected
      socketClient.connect();
    }

    const reconnectOnWindowFocus = () => {
      if (property && !propertyLoading && !isDemoOrg) {
        socketClient.connect();
      }
    };

    window.addEventListener("focus", reconnectOnWindowFocus);

    const handleConnect = () => {
      console.log("Kohost Socket.io client connected");
      failCount.current = 0;
      setConnected(true);
    };

    const handleDisconnect = (reason) => {
      console.log("Kohost Socket.io client disconnected:", reason);
      setConnected(false);
    };

    const handleConnectError = (err) => {
      console.error("Kohost Socket.io client connection error", err);
      setConnected(false);
      if (err.message === "The token has expired.") {
        //attempt to refresh token
        failCount.current++;
        if (failCount.current > 3) {
          const cmd = new RefreshTokenCommand({});
          const request = kohost.createRequest(cmd);
          kohost.send(request).then(() => {
            socketClient.connect();
          });
        }
      } else {
        if (timeout.current) clearTimeout(timeout.current);
        timeout.current = setTimeout(() => socketClient.connect(), 1000);
        captureException(err);
      }
    };

    const hasConnectLstnr = socketClient.listeners("connect").length > 0;
    const hasDisconnectLstnr = socketClient.listeners("disconnect").length > 0;
    const hasConnectErrorLstnr =
      socketClient.listeners("connect_error").length > 0;

    if (!hasConnectLstnr) socketClient.on("connect", handleConnect);
    if (!hasDisconnectLstnr) socketClient.on("disconnect", handleDisconnect);
    if (!hasConnectErrorLstnr)
      socketClient.on("connect_error", handleConnectError);

    return () => {
      clearTimeout(timeout.current);
      window.removeEventListener("focus", reconnectOnWindowFocus);
    };
  }, [
    currentUserId,
    connected,
    property,
    propertyLoading,
    socketClient,
    isDemoOrg,
  ]);

  return { connected, client: socketClient || {} };
};
