import * as Sentry from "@sentry/react";
import React from "react";
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from "react-router-dom";
import getOrganizationId from "utils/getOrganizationId";

const { VITE_SENTRY_DSN, PROD } = import.meta.env;

if (VITE_SENTRY_DSN) {
  Sentry.init({
    dsn: VITE_SENTRY_DSN,
    environment: PROD ? "production" : "development",
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration(),
      Sentry.reactRouterV6BrowserTracingIntegration({
        useEffect: React.useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      }),
    ],
    tracesSampleRate: PROD ? 0.1 : 1.0,
    replaysSessionSampleRate: PROD ? 0.1 : 0.03,
    initialScope: {
      tags: { propertyId: getOrganizationId() },
    },
  });
}

export const captureException = (error) => {
  if (error instanceof Error) {
    Sentry.captureException(error);
  } else if (error.type && error.message) {
    Sentry.captureException(new Error(`${error.type}: ${error.message}`));
  }
};
